if (module.hot && window.location.port !== '8000') {
    module.hot.accept();
}

import 'main/webpack-runtime-settings.js';
import 'util/detect';
import 'util/fixes';
import { installPolyfills, handleErrors } from 'util/polyfills';

window.googleRecaptchaCallback = function() {
    globals.state.googleRecaptchaLoaded = true;
    window.dispatchEvent(new CustomEvent('recaptcha-loaded'));
};

/**
 * Main entry point to the js app. Javascript execution starts here
 * 1. Webpack config and general fixes are initialized via `import` statement
 * 2. Polyfills are imported and asynchronously processed via `Promise` API
 * 3. In case of success, the vue app is initialized
 */
installPolyfills
    .then(() => require.ensure([], () => require('framework/base'), null, 'app-boot')) // eslint-disable-line global-require
    .then(app => app.init())
    .catch(handleErrors);
