/* eslint-disable global-require */
export default [
    {
        hasNativeSupport: () => typeof String.prototype.startsWith === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.string.starts-with'));
            }, null, 'polyfills/string-starts-with');
        },
    },
    {
        hasNativeSupport: () => typeof Array.from === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.from'));
            }, null, 'polyfills/array-from');
        },
    },
    {
        hasNativeSupport: () => typeof Array.of === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.of'));
            }, null, 'polyfills/array-of');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.find === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find'));
            }, null, 'polyfills/array-find');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.find-index'));
            }, null, 'polyfills/array-find-index');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.fill === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.fill'));
            }, null, 'polyfills/array-fill');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.filter === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.filter'));
            }, null, 'polyfills/array-filter');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.some === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.some'));
            }, null, 'polyfills/array-some');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.includes === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.array.includes'));
            }, null, 'polyfills/array-includes');
        },
    },
    {
        hasNativeSupport: () => typeof Object.assign === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.assign'));
            }, null, 'polyfills/object-assign');
        },
    },
    {
        hasNativeSupport: () => typeof Object.entries === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.entries'));
            }, null, 'polyfills/object-entries');
        },
    },
    {
        hasNativeSupport: () => typeof Object.values === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.object.values'));
            }, null, 'polyfills/object-values');
        },
    },
    {
        hasNativeSupport: () => typeof window.URLSearchParams === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                window.URLSearchParams = require('@ungap/url-search-params/cjs');
                resolve();
            }, null, 'polyfills/url-search-params');
        },
    },
    {
        hasNativeSupport: () => typeof document.scrollingElement === 'object',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('scrolling-element'));
            }, null, 'polyfills/scrolling-element');
        },
    },
    {
        hasNativeSupport: () => typeof (new FormData()).set === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('formdata-polyfill'));
            }, null, 'polyfills/formdata');
        },
    },
    {
        // NOTE: Smooth scroll is still buggy in several browsers
        hasNativeSupport: () => false,
        polyfill(resolve) {
            require.ensure([], () => {
                // NOTE: Reenable once vue-ui was updated to a next alpha release
                resolve();

                // window.__forceSmoothScrollPolyfill__ = true;
                // resolve(require('smoothscroll-polyfill').polyfill());
            }, null, 'polyfills/smoothscroll-polyfill');
        },
    },
    {
        hasNativeSupport: () => typeof Promise.prototype.finally === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es.promise.finally'));
            }, null, 'polyfills/promise-finally');
        },
    },
];
